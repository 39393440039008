<template>
    <v-container v-if="loading" class="px-6 pt-6">
        <v-skeleton-loader loading type="heading" width="50%" class="mb-4" />
        <v-skeleton-loader loading type="paragraph" />
    </v-container>
    <v-container v-else class="px-4">
        <v-btn text class="ml-n2" @click="toggle">
            <v-icon small left>
                {{ visible ? 'caret-down' : 'caret-right' }}
            </v-icon>
            Internal Details
        </v-btn>
        <div v-if="!loading">
            <v-slide-y-transition>
                <div v-if="visible" class="pt-3 px-2">
                    <a-form ref="form">
                        <a-text-input
                            v-model="notes.author"
                            label="Author Comments"
                            rules="max:4000"
                            :loading="isSaving"
                            textarea
                            rows="3"
                            clearable
                            auto-grow
                            @blur="save"
                        />
                        <a-text-input
                            v-model="notes.support"
                            label="Support Comments"
                            rules="max:4000"
                            :loading="isSaving"
                            textarea
                            rows="3"
                            clearable
                            auto-grow
                            @blur="save"
                        />
                        <a-text-input
                            v-model="notes.sales"
                            label="Sales/KAM Comments"
                            rules="max:4000"
                            :loading="isSaving"
                            textarea
                            rows="3"
                            clearable
                            auto-grow
                            @blur="save"
                        />
                    </a-form>
                </div>
            </v-slide-y-transition>
        </div>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive, Watch } from '@/utils/decorators';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { Assignment } from '@/types/Assignment';

type AuthorRequestDescription = {
    details: string;
    theme: string;
    links: string;
    notes: {
        author: string;
        support: string;
        sales: string;
    };
};

const AssignmentInternalDetailsProps = Vue.extend({
    name: 'AssignmentInternalDetails',
    props: {
        assignmentId: {
            type: Number,
            default() {
                return 0;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component({
    components: {
        AForm,
        ATextInput
    }
})
export default class AssignmentInternalDetails extends AssignmentInternalDetailsProps {
    $refs!: {
        form: InstanceType<typeof AForm>;
    };

    isSaving = false;
    visible = false;

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    @Watch('assignment')
    onRequestChange() {
        if (this.description.notes) {
            this.notes = this.description.notes;
            this.visible = Boolean(
                this.notes?.author?.length ||
                    this.notes?.support?.length ||
                    this.notes?.sales?.length
            );
        } else {
            this.notes = {
                author: '',
                support: '',
                sales: ''
            };
            this.visible = false;
        }
    }

    notes = {
        author: '',
        support: '',
        sales: ''
    };

    get description(): Partial<AuthorRequestDescription> {
        if (!this.assignment.author_request.description) {
            return {};
        }

        return JSON.parse(this.assignment.author_request.description);
    }

    toggle() {
        this.visible = !this.visible;
    }

    save() {
        if (this.$refs.form.isDirty) {
            this.setSaving();

            return this.setData()
                .then(() => this.$refs.form.reset())
                .finally(this.setSaving.bind(this, false));
        }
    }

    setData() {
        return this.$http
            .post(
                `/author_requests_pool/employee_comments/${this.assignmentId}`,
                this.getDataToSave()
            )
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    getDataToSave() {
        return {
            description: JSON.stringify({
                ...this.description,
                notes: this.notes
            })
        };
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }
}
</script>
