<template>
    <div>
        <v-tooltip left>
            <template #activator="{ on, attrs }">
                <v-btn
                    fab
                    dark
                    small
                    :color="action.color"
                    :to="href"
                    v-bind="attrs"
                    @click="onAction"
                    v-on="on"
                >
                    <v-icon v-if="action.icon" small color="white">
                        {{ action.icon }}
                    </v-icon>
                    <v-chip
                        v-else-if="action.iconText"
                        color="turquoise"
                        text-color="white"
                        class="pa-1"
                        small
                        link
                    >
                        {{ action.iconText }}
                    </v-chip>
                </v-btn>
            </template>
            <span>{{ action.name }}</span>
        </v-tooltip>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { AssignmentAction } from './AssignmentActionsFactory';
import type { Assignment } from '@/types/Assignment';

const AuthorRequestActionProps = Vue.extend({
    name: 'AuthorRequestAction',
    props: {
        assignment: {
            type: Object as PropType<Assignment>,
            required: true
        },
        action: {
            type: Object as PropType<AssignmentAction>,
            default() {
                return {
                    name: '',
                    color: '',
                    route: false,
                    icon: '',
                    iconText: '',
                    callback() {
                        return void 0;
                    }
                };
            }
        }
    }
});

@Component
export default class AuthorRequestAction extends AuthorRequestActionProps {
    get href() {
        return this.action.route ? this.execute() : void 0;
    }

    onAction() {
        if (!this.action.route) {
            this.execute();
        }
    }

    execute() {
        return this.action.callback(this, this.assignment);
    }
}
</script>
