/* eslint-disable complexity */
import Vue from 'vue';

import { Assignment } from '@/types/Assignment';

export type AssignmentAction = {
    name: string;
    icon?: string;
    color?: string;
    iconText?: string;
    action: string;
    route?: boolean;
    callback: (context: Vue, assignment: Assignment) => void | string;
};

const ASSIGNMENT_STATUS = {
    DRAFT: 0,
    PROGRESS: 1,
    EDITORIAL: 2,
    SENT: 3,
    FLAGGED: 4
};

const actions: Record<string, AssignmentAction> = {
    review: {
        name: 'Comments',
        icon: 'comment',
        color: 'turquoise',
        action: 'review',
        callback(context, { id }) {
            context.$emit('show-comments', id);
        }
    },
    edit: {
        name: 'Edit',
        icon: 'edit',
        color: 'primary',
        action: 'edit',
        route: true,
        callback(_context, { author_request }) {
            return author_request
                ? `/author-requests/create/${author_request.id}`
                : '';
        }
    },
    remove: {
        name: 'Delete',
        icon: 'trash-alt',
        color: 'error',
        action: 'delete',
        callback(context) {
            context.$store.dispatch('modal/open', 'author-request-delete');
        }
    },
    write: {
        name: 'Edit Amp',
        icon: 'edit',
        color: 'primary',
        action: 'write',
        route: true,
        callback(_context, { announcement }) {
            // don't define target module in the link, use routes.ts
            return announcement ? `/announcements/edit/${announcement.id}` : '';
        }
    },
    finish: {
        name: 'Send To Editor',
        icon: 'paper-plane',
        color: 'green',
        action: 'finish',
        callback(context) {
            context.$store.dispatch(
                'modal/open',
                'author-request-send-to-editor'
            );
        }
    },
    unassign: {
        name: 'Unassign',
        icon: 'user-slash',
        color: 'error',
        action: 'unassign',
        callback(context) {
            context.$store.dispatch('modal/open', 'author-request-unassign');
        }
    },
    priority: {
        name: 'Assign Priority',
        icon: 'square-check',
        color: 'blue-grey lighten-3',
        action: 'priority',
        callback(context) {
            context.$store.dispatch('modal/open', 'author-request-prioritize');
        }
    },
    limit: {
        name: 'Selective Distribution',
        iconText: 'SD',
        color: 'blue lighten-1',
        action: 'limit',
        callback(context) {
            context.$store.dispatch(
                'modal/open',
                'author-request-limited-distribution'
            );
        }
    },
    reject: {
        name: 'Reject',
        icon: 'ban',
        color: 'error',
        action: 'reject',
        callback(context) {
            context.$store.dispatch('modal/open', 'author-request-reject');
        }
    },
    claim: {
        name: 'Claim',
        icon: 'user',
        color: 'green',
        action: 'assign',
        callback(context) {
            context.$store.dispatch('modal/open', 'author-request-assign');
        }
    },
    send: {
        name: 'Send To Customer',
        icon: 'paper-plane',
        color: 'green',
        action: 'customer',
        callback(context) {
            context.$store.dispatch(
                'modal/open',
                'author-request-send-to-customer'
            );
        }
    },
    deny: {
        name: 'Send To Author',
        icon: 'paragraph',
        color: 'warning',
        action: 'deny',
        callback(context) {
            context.$store.dispatch(
                'modal/open',
                'author-request-send-to-author'
            );
        }
    },
    recurring: {
        name: 'See Recurring',
        icon: 'rotate',
        color: 'primary',
        action: 'recurring',
        route: true,
        callback(_context, { author_request }) {
            return author_request
                ? `/author-requests/review/${author_request.id}`
                : '';
        }
    },
    flag: {
        name: 'Add/Remove Flag',
        icon: 'flag',
        color: 'error',
        action: 'flag',
        callback(context) {
            context.$store.dispatch('modal/open', 'author-request-flag');
        }
    },
    settings: {
        name: 'Request Settings',
        icon: 'gear',
        color: 'secondary',
        action: 'settings',
        route: true,
        callback(_context, { author_request_id }) {
            return author_request_id
                ? `/author-requests/settings/${author_request_id}`
                : '';
        }
    }
};

export default class AssignmentActionsFactory {
    assignment: Partial<Assignment>;
    isEditor = false;
    isAuthor = false;

    constructor(
        assignment: Partial<Assignment>,
        isEditor = false,
        isAuthor = false
    ) {
        this.assignment = assignment;
        this.isEditor = isEditor;
        this.isAuthor = isAuthor;
    }

    get editable() {
        return (
            this.assignment.status === ASSIGNMENT_STATUS.DRAFT && !this.rejected
        );
    }

    get rejected() {
        return this.assignment.author_request?.rejected_time;
    }

    get recurring() {
        return this.assignment.author_request?.frequency !== null;
    }

    get writable() {
        return (
            !this.rejected &&
            [
                ASSIGNMENT_STATUS.PROGRESS,
                ASSIGNMENT_STATUS.EDITORIAL,
                ASSIGNMENT_STATUS.FLAGGED
            ].includes(this.assignment.status ?? 0)
        );
    }

    get editorial() {
        return [ASSIGNMENT_STATUS.EDITORIAL].includes(
            this.assignment.status ?? 0
        );
    }

    get sent() {
        return [ASSIGNMENT_STATUS.SENT].includes(this.assignment.status ?? 0);
    }

    get flagged() {
        return [ASSIGNMENT_STATUS.FLAGGED].includes(
            this.assignment?.status ?? 0
        );
    }

    get hasAmp() {
        return this.assignment.announcement_id ?? false;
    }

    get assignedToSelf() {
        return this.assignment.is_assigned_to_current_user ?? false;
    }

    get assigned() {
        return this.assignment.author_id ?? 0;
    }

    getActions() {
        return Array.from(this.getUniqueActions()).filter(Boolean);
    }

    getBaseActions() {
        if (this.editable) {
            return new Set<AssignmentAction>([actions.edit, actions.remove]);
        }

        const baseActions = new Set([actions.review]);

        if (this.hasAmp && this.sent) {
            baseActions.add(actions.write);
        }

        if (this.rejected && !this.hasAmp) {
            baseActions.add(actions.remove);
        }

        if (this.recurring && !this.rejected) {
            baseActions.add(actions.settings);
        }

        return baseActions;
    }

    getUniqueActions() {
        if (!(this.isAuthor || this.isEditor)) {
            return this.getBaseActions();
        }

        const availableActions = new Set([actions.review]);

        if (
            this.isAuthor &&
            this.writable &&
            (!this.assigned || this.assignedToSelf) &&
            !this.flagged
        ) {
            availableActions.add(actions.flag);
        }

        if (this.isEditor && (this.writable || this.flagged)) {
            availableActions.add(actions.flag);
        }

        if (this.isAuthor && this.writable && this.assignedToSelf) {
            availableActions.add(actions.write);
        }

        if (
            this.isAuthor &&
            this.writable &&
            this.assignedToSelf &&
            !this.sent &&
            !this.editorial
        ) {
            availableActions.add(actions.finish).add(actions.unassign);
        }

        if (this.isEditor && this.assigned) {
            availableActions.add(actions.write).add(actions.unassign);
        }

        if (this.recurring) {
            availableActions.add(actions.recurring);
        }

        if (!this.assigned) {
            availableActions.add(actions.claim);
        }

        if (this.isEditor && (this.editorial || this.sent)) {
            availableActions.add(actions.deny);
        }

        if (this.isEditor) {
            availableActions
                .add(actions.send)
                .add(actions.limit)
                .add(actions.priority)
                .add(actions.reject);
        }

        if (this.recurring && !this.rejected && this.isEditor) {
            availableActions.add(actions.settings);
        }

        return availableActions;
    }

    hasAction(action: string) {
        const found = this.getActions().find(
            available => available.action === action
        );
        return !!found;
    }
}
