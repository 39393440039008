<template>
    <v-container v-if="loading" class="px-6 pt-6 d-flex flex-column">
        <v-skeleton-loader loading type="heading" width="50%" class="mb-4" />
        <v-skeleton-loader
            loading
            type="list-item-avatar-three-line"
            class="message-skeleton align-self-end mr-n4"
            width="75%"
        />
    </v-container>
    <v-container v-else class="px-4">
        <v-row>
            <v-col cols="6">
                <v-btn v-if="hasComments" text class="ml-n2" @click="toggle">
                    <v-icon small left>
                        {{ visible ? 'caret-down' : 'caret-right' }}
                    </v-icon>
                    Show Comments
                </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
                <v-btn v-if="canShowAll" text class="mr-n2" @click="showAll">
                    Load Campaign Comments
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <v-slide-y-transition>
                <div v-if="visible" class="pt-3 px-2">
                    <p
                        v-if="!hasComments"
                        class="d-flex justify-center align-center"
                    >
                        <v-icon left small>comment</v-icon>
                        No comments to show
                    </p>
                    <assignment-comment-card
                        v-for="(comment, index) in availableComments"
                        :key="index"
                        :comment="comment"
                    >
                        <span
                            v-if="index === availableComments.length - 1"
                            ref="lastComment"
                        ></span>
                    </assignment-comment-card>
                </div>
            </v-slide-y-transition>
        </div>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { InjectReactive } from '@/utils/decorators';
import { getScrollParent, wait } from '@/utils/helpers';

import AssignmentCommentCard from './AssignmentCommentCard.vue';

import type { Assignment } from '@/types/Assignment';
import type { Comment } from '@/types/Comment';

const AssignmentCommentsListProps = Vue.extend({
    name: 'AssignmentCommentsList',
    props: {
        comments: {
            type: Array as PropType<Comment[]>,
            default() {
                return [];
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        all: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AssignmentCommentCard
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AssignmentCommentsList extends AssignmentCommentsListProps {
    isAuthor!: boolean;
    isEditor!: boolean;
    $refs!: {
        lastComment: HTMLSpanElement[];
    };

    visible = true;

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get hasComments() {
        return Boolean(this.availableComments.length);
    }

    get availableComments(): Comment[] {
        if (!this.comments?.length) {
            return [];
        }

        return this.comments?.reduce((acc: Comment[], comment: Comment) => {
            const last = acc.length ? acc[acc.length - 1] : false;

            if (
                (this.isAuthor || this.isEditor) &&
                (!last || last.message !== comment.message)
            ) {
                acc.push(comment);
            } else {
                if (
                    comment.user_id &&
                    [0, 1, 2, 5].includes(comment.message_type ?? -1)
                ) {
                    acc.push(comment);
                }
            }

            return acc;
        }, []);
    }

    get canShowAll() {
        return !this.all && this.assignment.author_request.frequency !== null;
    }

    toggle() {
        this.visible = !this.visible;
    }

    showAll() {
        this.$emit('show-all', true);
    }

    async scrollToLast() {
        await wait(() => this.$refs.lastComment.length);

        const lastComment = this.$refs.lastComment[0];

        if (lastComment) {
            const container = getScrollParent(lastComment) as HTMLElement;

            if (container) {
                this.$vuetify.goTo(lastComment, {
                    container
                });
            }
        }
    }
}
</script>

<style lang="scss">
.message-skeleton {
    transform: rotateY(180deg);
}
</style>
