<template>
    <modal :id="id" title="Unassign author">
        <template #default>
            <p>
                Currently claimed by
                <strong class="text-capitalize">{{ authorName }}</strong>
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="send"
            >
                Unassign
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';

import type { Assignment } from '@/types/Assignment';

@Component({
    components: {
        Modal
    }
})
export default class AssignmentUnassign extends Vue {
    id = 'author-request-unassign';

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get authorName() {
        if (!this.assignment?.author) {
            return '-';
        }

        return [
            this.assignment?.author?.firstname,
            this.assignment?.author?.lastname
        ]
            .filter(Boolean)
            .join(' ');
    }

    send() {
        this.$emit('confirm', {
            action: 'assign',
            data: {
                unassign: true
            }
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
