<template>
    <modal :id="id" title="Prioritize Request">
        <template #default>
            <a-priority-select-input
                v-model="priorityLevel"
                item-value="priority_level"
            />
            <v-checkbox
                v-if="isRecurring"
                v-model="isPriority"
                label="Mark recurring request and all future assignments as priority?"
            />
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="prioritize"
            >
                Confirm
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';
import APrioritySelectInput from '@/components/AForm/Inputs/APrioritySelectInput';

import type { Assignment } from '@/types/Assignment';

@Component({
    components: {
        Modal,
        APrioritySelectInput
    }
})
export default class AssignmentPrioritize extends Vue {
    id = 'author-request-prioritize';

    nonePriorityLevel = 4;
    isPriority = true;

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get isRecurring() {
        return this.assignment.author_request?.frequency !== null;
    }

    get priorityLevel() {
        return this.assignment.priority_level || null;
    }

    set priorityLevel(value) {
        if (value) {
            this.assignment.priority_level = value;
        }
    }

    prioritize() {
        this.$emit('confirm', {
            action: 'prioritize',
            data: {
                priority_level: this.priorityLevel,
                is_priority: this.isPriority
            }
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
