<template>
    <modal :id="id" title="Reject Request">
        <template #default>
            <p>This will reject and refund this author request</p>
            <p v-if="isRecurring">
                This is a recurring request and any current assignments will be
                cancelled.
            </p>
            <v-checkbox
                v-model="notifySupport"
                label="Would you like to notify the support team about this cancellation?"
            />
            <v-checkbox
                v-model="refund"
                label="Should this request be refunded?"
            />
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="reject"
            >
                Reject
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';

import type { Assignment } from '@/types/Assignment';

@Component({
    components: {
        Modal
    }
})
export default class AssignmentReject extends Vue {
    id = 'author-request-reject';

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    notifySupport = false;

    refund = false;

    get isRecurring() {
        return this.assignment.author_request?.frequency !== null;
    }

    reject() {
        this.$emit('confirm', {
            action: 'reject',
            data: {
                notifySupport: this.notifySupport,
                refund: this.refund
            }
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
