<template>
    <v-container v-if="loading" class="px-6 pt-6">
        <v-skeleton-loader loading type="heading" width="50%" class="mb-4" />
        <v-skeleton-loader loading type="paragraph" />
    </v-container>
    <v-container v-else class="px-4">
        <v-btn text class="ml-n2" @click="toggle">
            <v-icon small left>
                {{ visible ? 'caret-down' : 'caret-right' }}
            </v-icon>
            Original Request Details
        </v-btn>
        <div v-if="!loading">
            <v-slide-y-transition>
                <div v-if="visible" class="pt-3 px-2">
                    <template v-if="!hasDetails">
                        <p class="d-flex justify-center align-center">
                            <v-icon left small>circle-info</v-icon>
                            No details to show
                        </p>
                    </template>
                    <!-- eslint-disable vue/no-v-html -->
                    <template v-if="description.theme">
                        <label class="v-label font-weight-bold">
                            Recommend a main theme for your Amp
                        </label>

                        <p v-html="format(description.theme)"></p>
                    </template>

                    <template v-if="description.details">
                        <label class="v-label font-weight-bold">
                            What are your target keywords for this campaign?
                        </label>

                        <p v-html="format(description.details)"></p>
                    </template>

                    <template v-if="description.links">
                        <label class="v-label font-weight-bold">
                            Provide a link (or links) that you would like to
                            promote with this Amp
                        </label>

                        <p v-html="format(description.links)"></p>
                    </template>
                    <!-- eslint-enable -->
                </div>
            </v-slide-y-transition>
        </div>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';
import { nl2br, urlify } from '@/utils/helpers';

import type { Assignment } from '@/types/Assignment';

type AssignmentDescription = {
    details: string;
    theme: string;
    links: string;
};

const AssignmentCommentsDetailsProps = Vue.extend({
    name: 'AssignmentCommentsDetails',
    props: {
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component
export default class AssignmentCommentsDetails extends AssignmentCommentsDetailsProps {
    visible = true;

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get description(): Partial<AssignmentDescription> {
        if (!this.assignment.author_request.description) {
            return {};
        }

        return JSON.parse(this.assignment.author_request.description);
    }

    get hasDetails() {
        return Boolean(
            this.description.theme ||
                this.description.details ||
                this.description.links
        );
    }

    toggle() {
        this.visible = !this.visible;
    }

    format(text: string) {
        return nl2br(urlify(text));
    }
}
</script>
