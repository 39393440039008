<template>
    <modal :id="id" title="Send to Author">
        <template #default>
            <p>
                This will send the request back to the request pool or author's
                assignments.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="send"
            >
                Send
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

@Component({
    components: {
        Modal
    }
})
export default class AssignmentSendToAuthor extends Vue {
    id = 'author-request-send-to-author';

    send() {
        this.$emit('confirm', {
            action: 'deny'
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
