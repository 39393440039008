import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VContainer,{staticClass:"px-6 pt-6"},[_c(VSkeletonLoader,{staticClass:"mb-4",attrs:{"loading":"","type":"heading","width":"50%"}}),_c(VSkeletonLoader,{attrs:{"loading":"","type":"paragraph"}})],1):_c(VContainer,{staticClass:"px-4"},[_c(VBtn,{staticClass:"ml-n2",attrs:{"text":""},on:{"click":_vm.toggle}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.visible ? 'caret-down' : 'caret-right')+" ")]),_vm._v(" Original Request Details ")],1),(!_vm.loading)?_c('div',[_c(VSlideYTransition,[(_vm.visible)?_c('div',{staticClass:"pt-3 px-2"},[(!_vm.hasDetails)?[_c('p',{staticClass:"d-flex justify-center align-center"},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("circle-info")]),_vm._v(" No details to show ")],1)]:_vm._e(),(_vm.description.theme)?[_c('label',{staticClass:"v-label font-weight-bold"},[_vm._v(" Recommend a main theme for your Amp ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.format(_vm.description.theme))}})]:_vm._e(),(_vm.description.details)?[_c('label',{staticClass:"v-label font-weight-bold"},[_vm._v(" What are your target keywords for this campaign? ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.format(_vm.description.details))}})]:_vm._e(),(_vm.description.links)?[_c('label',{staticClass:"v-label font-weight-bold"},[_vm._v(" Provide a link (or links) that you would like to promote with this Amp ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.format(_vm.description.links))}})]:_vm._e()],2):_vm._e()])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }