<template>
    <modal :id="id" :title="title">
        <template #default>
            <p>
                This will
                {{ isLimitedDistribution ? 'remove' : 'set' }} selective
                distribution,
                {{ isLimitedDistribution ? 'allowing' : 'disallowing' }} access
                to modules other than the News Article.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="limit"
            >
                Update
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';

import type { Assignment } from '@/types/Assignment';

@Component({
    components: {
        Modal
    }
})
export default class AssignmentLimitedDistribution extends Vue {
    id = 'author-request-limited-distribution';

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get title() {
        return `${
            this.isLimitedDistribution ? 'Remove' : 'Set'
        } Selective Distribution`;
    }

    get isLimitedDistribution() {
        return this.assignment?.limited_distribution_only || false;
    }

    limit() {
        this.$emit('confirm', {
            action: 'limit',
            data: {
                limitedDistribution: !this.isLimitedDistribution
            }
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
