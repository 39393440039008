<template>
    <modal :id="id" :title="title">
        <template #default>
            <p v-if="isFlagged">
                This will
                <strong>remove the flag</strong>
                from the request and send it back into the author pool.
            </p>
            <p v-else>
                This will
                <strong>flag</strong>
                the request and send it to the editors.
            </p>
            <v-spacer />
            <label class="v-label">Comment:</label>
            <v-textarea
                v-model="reason"
                name="reason"
                color="secondary"
                filled
                auto-grow
            />
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="assign"
            >
                Confirm
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';

import type { Assignment } from '@/types/Assignment';

@Component({
    components: {
        Modal
    }
})
export default class AssignmentFlag extends Vue {
    id = 'author-request-flag';
    reason = '';

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get title() {
        return `${this.isFlagged ? 'Remove Flag from' : 'Flag'} Request`;
    }

    get isFlagged() {
        return this.assignment?.status === 4;
    }

    assign() {
        this.$emit('confirm', {
            action: 'flag',
            data: {
                reason: this.reason
            }
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
