import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VContainer,{staticClass:"px-6 pt-6"},[_c(VSkeletonLoader,{staticClass:"mb-4",attrs:{"loading":"","type":"heading","width":"50%"}}),_c(VSkeletonLoader,{attrs:{"loading":"","type":"paragraph"}})],1):_c(VContainer,{staticClass:"px-4"},[_c(VBtn,{staticClass:"ml-n2",attrs:{"text":""},on:{"click":_vm.toggle}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.visible ? 'caret-down' : 'caret-right')+" ")]),_vm._v(" Internal Details ")],1),(!_vm.loading)?_c('div',[_c(VSlideYTransition,[(_vm.visible)?_c('div',{staticClass:"pt-3 px-2"},[_c('a-form',{ref:"form"},[_c('a-text-input',{attrs:{"label":"Author Comments","rules":"max:4000","loading":_vm.isSaving,"textarea":"","rows":"3","clearable":"","auto-grow":""},on:{"blur":_vm.save},model:{value:(_vm.notes.author),callback:function ($$v) {_vm.$set(_vm.notes, "author", $$v)},expression:"notes.author"}}),_c('a-text-input',{attrs:{"label":"Support Comments","rules":"max:4000","loading":_vm.isSaving,"textarea":"","rows":"3","clearable":"","auto-grow":""},on:{"blur":_vm.save},model:{value:(_vm.notes.support),callback:function ($$v) {_vm.$set(_vm.notes, "support", $$v)},expression:"notes.support"}}),_c('a-text-input',{attrs:{"label":"Sales/KAM Comments","rules":"max:4000","loading":_vm.isSaving,"textarea":"","rows":"3","clearable":"","auto-grow":""},on:{"blur":_vm.save},model:{value:(_vm.notes.sales),callback:function ($$v) {_vm.$set(_vm.notes, "sales", $$v)},expression:"notes.sales"}})],1)],1):_vm._e()])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }