import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VContainer,{staticClass:"px-6 pt-6 d-flex flex-column"},[_c(VSkeletonLoader,{staticClass:"mb-4",attrs:{"loading":"","type":"heading","width":"50%"}}),_c(VSkeletonLoader,{staticClass:"message-skeleton align-self-end mr-n4",attrs:{"loading":"","type":"list-item-avatar-three-line","width":"75%"}})],1):_c(VContainer,{staticClass:"px-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[(_vm.hasComments)?_c(VBtn,{staticClass:"ml-n2",attrs:{"text":""},on:{"click":_vm.toggle}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.visible ? 'caret-down' : 'caret-right')+" ")]),_vm._v(" Show Comments ")],1):_vm._e()],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[(_vm.canShowAll)?_c(VBtn,{staticClass:"mr-n2",attrs:{"text":""},on:{"click":_vm.showAll}},[_vm._v(" Load Campaign Comments ")]):_vm._e()],1)],1),(!_vm.loading)?_c('div',[_c(VSlideYTransition,[(_vm.visible)?_c('div',{staticClass:"pt-3 px-2"},[(!_vm.hasComments)?_c('p',{staticClass:"d-flex justify-center align-center"},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("comment")]),_vm._v(" No comments to show ")],1):_vm._e(),_vm._l((_vm.availableComments),function(comment,index){return _c('assignment-comment-card',{key:index,attrs:{"comment":comment}},[(index === _vm.availableComments.length - 1)?_c('span',{ref:"lastComment",refInFor:true}):_vm._e()])})],2):_vm._e()])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }