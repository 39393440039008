<template>
    <v-navigation-drawer
        v-model="isOpen"
        class="assignment-comments-drawer"
        app
        right
        temporary
        :width="width"
        @transitionend="onSlide"
    >
        <template #prepend>
            <assignment-comments-header
                :loading="isBusy"
                class="comments-header"
                @close="close"
                @update="update"
            />
        </template>

        <assignment-comments-details :loading="isBusy" />

        <v-divider />

        <assignment-internal-details
            v-if="$can('manage', 'AuthorRequestInternalDetails')"
            :assignment-id="assignmentId"
            :loading="isBusy"
        />

        <v-divider v-if="$can('manage', 'AuthorRequestInternalDetails')" />

        <assignment-comments-list
            ref="list"
            :comments="comments"
            :loading="isBusy"
            :all="showAllComments"
            @show-all="showAll"
        />

        <template #append>
            <assignment-comments-input
                :allow-comments="allowComments"
                :loading="isBusy"
                @send="reload"
            />
        </template>
    </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ProvideReactive, Watch } from '@/utils/decorators';

import AssignmentCommentsDetails from './AssignmentCommentsDetails.vue';
import AssignmentCommentsHeader from './AssignmentCommentsHeader.vue';
import AssignmentCommentsInput from './AssignmentCommentsInput.vue';
import AssignmentCommentsList from './AssignmentCommentsList.vue';
import AssignmentInternalDetails from './AssignmentInternalDetails.vue';

import type { Assignment } from '@/types/Assignment';
import type { Comment } from '@/types/Comment';

const AssignmentCommentsProps = Vue.extend({
    name: 'AssignmentComments',
    props: {
        assignmentId: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component<AssignmentComments>({
    components: {
        AssignmentCommentsHeader,
        AssignmentCommentsDetails,
        AssignmentInternalDetails,
        AssignmentCommentsList,
        AssignmentCommentsInput
    }
})
export default class AssignmentComments extends AssignmentCommentsProps {
    $refs!: {
        list: InstanceType<typeof AssignmentCommentsList>;
    };

    isOpen = false;
    isLoading = true;
    isReady = false;

    showAllComments = false;

    controller: AbortController | null = null;

    @ProvideReactive() assignment: Assignment | null = null;
    @ProvideReactive() assignment_number: number | null = null;
    @ProvideReactive() comments: Comment[] = [];
    @ProvideReactive() allowComments = false;

    @Watch('assignmentId')
    onRequestChange(id: number) {
        if (id) {
            this.open();
            this.load();
        }
    }

    @Watch('isOpen')
    onUpdate() {
        if (!this.isOpen) {
            this.$emit('close');

            this.abort();

            this.showAllComments = false;
        }
    }

    get isBusy() {
        return this.isLoading || !this.isReady;
    }

    get width() {
        return this.$vuetify.breakpoint.smAndDown ? '100%' : '50%';
    }

    open() {
        this.isOpen = true;
    }

    close() {
        this.isOpen = false;
    }

    update() {
        // update will reload the context, so we can just close
        this.$emit('close');

        this.$nextTick(() => {
            this.$emit('update', true);
        });
    }

    setLoading(isLoading = true) {
        this.isLoading = isLoading;
    }

    setReady(isReady = true) {
        this.isReady = isReady;
    }

    load() {
        this.setLoading();

        return this.getData()
            .then(({ data }) => {
                if (data) {
                    this.assignment = data.assignment;
                    this.assignment_number = data.assignment_number;
                    this.comments = data.comments;
                    this.allowComments = data.allowComments;

                    this.setReady();
                }
            })
            .finally(this.setLoading.bind(this, false));
    }

    async getData() {
        this.abort(true);

        const path = [`/author_requests_pool/comments/${this.assignmentId}`];

        if (this.showAllComments) {
            path.push('showAllComments=1');
        }

        return this.$http
            .get(path.join('?'), {
                signal: this.controller?.signal,
                cache: false
            })
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    abort(createNew = false) {
        if (this.controller) {
            if (!this.isReady) {
                this.controller.abort();
            }
            this.controller = null;
        }

        if (createNew) {
            this.controller = new AbortController();
        }
    }

    onSlide() {
        if (!this.isOpen) {
            // this will reset all child components to initial state
            this.setReady(false);
        }
    }

    showAll(showAll = false) {
        this.showAllComments = showAll;

        this.load();
    }

    async reload() {
        await this.load();

        this.$refs.list.scrollToLast();
    }
}
</script>

<style lang="scss">
.assignment-comments-drawer {
    max-width: 600px !important;

    .comments-header {
        border-bottom: 1px solid $tertiary-color;
    }
}
</style>
