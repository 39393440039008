<template>
    <span>
        <assignment-assign @confirm="confirm" />
        <assignment-unassign @confirm="confirm" />
        <assignment-reject @confirm="confirm" />
        <assignment-flag @confirm="confirm" />
        <assignment-limited-distribution @confirm="confirm" />
        <assignment-prioritize @confirm="confirm" />
        <assignment-send-to-author @confirm="confirm" />
        <assignment-send-to-customer @confirm="confirm" />
        <assignment-send-to-editor @confirm="confirm" />
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import AssignmentAssign from './AssignmentAssign.vue';
import AssignmentFlag from './AssignmentFlag.vue';
import AssignmentLimitedDistribution from './AssignmentLimitedDistribution.vue';
import AssignmentPrioritize from './AssignmentPrioritize.vue';
import AssignmentReject from './AssignmentReject.vue';
import AssignmentSendToAuthor from './AssignmentSendToAuthor.vue';
import AssignmentSendToCustomer from './AssignmentSendToCustomer.vue';
import AssignmentSendToEditor from './AssignmentSendToEditor.vue';
import AssignmentUnassign from './AssignmentUnassign.vue';

import type { Assignment } from '@/types/Assignment';

type ActionPayload = {
    action: string;
    data?: Record<string, string | number | boolean>;
};

@Component({
    components: {
        AssignmentAssign,
        AssignmentFlag,
        AssignmentLimitedDistribution,
        AssignmentPrioritize,
        AssignmentReject,
        AssignmentSendToAuthor,
        AssignmentSendToCustomer,
        AssignmentSendToEditor,
        AssignmentUnassign
    }
})
export default class AssignmentActionDialogs extends Vue {
    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    confirm(payload: ActionPayload) {
        this.applyAction(payload).then(({ data }) => {
            if (data) {
                if (data.redirect) {
                    this.redirect(data.redirect);
                } else {
                    if (data.error && data.message) {
                        this.$store.dispatch(
                            'notification/error',
                            data.message
                        );
                    } else {
                        this.$store.dispatch(
                            'notification/success',
                            'Request updated'
                        );

                        this.update();
                    }
                }
            }
        });
    }

    redirect(redirect: string) {
        if (redirect) {
            // let parents know about redirect
            this.$emit('redirect', redirect);
        }
    }

    async applyAction({ action, data = {} }: ActionPayload) {
        return this.$http
            .post(`/author_requests_pool/${action}/${this.assignment.id}`, data)
            .then(({ data: actionData }) => actionData)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to update request. Please try again later or contact support.'
                    );
                }

                return {};
            });
    }

    update() {
        // ask parent to reload
        this.$emit('update', true);
    }
}
</script>
