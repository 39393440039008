<template>
    <v-container v-if="loading" class="px-4 pt-6 main-background">
        <v-skeleton-loader loading type="heading" width="50%" class="mb-4" />
        <v-skeleton-loader loading type="heading" class="mb-2" />
        <v-skeleton-loader loading type="text" width="30%" />
    </v-container>
    <v-container v-else class="pa-0 main-background">
        <v-list-item three-line>
            <v-list-item-content>
                <v-subheader class="px-0">
                    <status-chip :status="assignment.status_string" />
                    <status-chip
                        v-if="assignment.author_request.frequency"
                        status="Recurring"
                        class="ml-2"
                    />
                    <status-chip
                        v-if="isLimitedDistribution"
                        status="Selective Distribution"
                        class="ml-2"
                    />
                    <status-chip
                        v-if="isPremium"
                        status="Premium"
                        class="ml-2"
                    />
                    <status-chip
                        v-if="isFinancial"
                        status="Financial"
                        class="ml-2"
                    />
                    <status-chip v-if="isMsn" status="MSN" class="ml-2" />
                    <status-chip
                        v-if="isFirstRecurring"
                        status="First Recurring Request"
                        class="ml-2"
                    />
                </v-subheader>
                <v-list-item-title class="title">
                    <v-tooltip bottom :disabled="companyName.length <= 25">
                        <template #activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                {{ truncate(companyName, 25) }}
                            </span>
                        </template>
                        <span>{{ companyName }}</span>
                    </v-tooltip>
                    <v-tooltip
                        v-if="isAuthor || isEditor"
                        bottom
                        :close-delay="copied ? 2000 : 1000"
                        :nudge-right="copied ? 20 : 0"
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn
                                v-clipboard:copy="commentsLink"
                                v-clipboard:success="onLinkCopied"
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon small>copy</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ copyTooltip }}
                        </span>
                    </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ category }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="isRecurring" class="mt-2 d-flex">
                    <v-icon small left>info-circle</v-icon>
                    <strong class="pr-1">#{{ assignment_number }}</strong>
                    in request
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="canSeeDates" class="mt-2 d-flex">
                    <v-icon small left>info-circle</v-icon>
                    Amp created on
                    <strong class="px-1">{{ announcementCreatedTime }}</strong>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    v-if="canSeeDates && isAssigned"
                    class="mt-2 d-flex"
                >
                    <v-icon small left>info-circle</v-icon>
                    Claimed by
                    <strong class="text-capitalize px-1">
                        {{ authorName }}
                    </strong>
                    on {{ authorAssignedTime }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <assignment-actions
            can-change-frequency
            @close="close"
            @update="update"
            @redirect="redirect"
        />
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { InjectReactive } from '@/utils/decorators';
import { formatDateTime, truncate } from '@/utils/helpers';

import { StatusChip } from '@/components/StatusChip';
import { AssignmentActions } from '@/components/Authoring/AssignmentActions';

import type { Assignment } from '@/types/Assignment';

const AssignmentCommentsHeaderProps = Vue.extend({
    name: 'AssignmentCommentsHeader',
    props: {
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component({
    components: {
        AssignmentActions,
        StatusChip
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AssignmentCommentsHeader extends AssignmentCommentsHeaderProps {
    isAuthor!: boolean;
    isEditor!: boolean;
    copied = false;

    truncate = truncate;

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    @InjectReactive({
        from: 'assignment_number',
        default() {
            return 1;
        }
    })
    assignment_number!: number;

    get isPremium() {
        return this.assignment.announcement?.is_premium ?? false;
    }

    get isFinancial() {
        return this.assignment.announcement?.is_financial ?? false;
    }

    get isMsn() {
        return this.assignment.announcement?.is_msn ?? false;
    }

    get isLimitedDistribution() {
        return this.assignment?.limited_distribution_only ?? false;
    }

    get isAssigned() {
        return this.assignment?.author_id ?? false;
    }

    get isRecurring() {
        return this.assignment.author_request.frequency !== null;
    }

    get isFirstRecurring() {
        return this.isRecurring && this.assignment.is_first_recurring;
    }

    get authorName() {
        if (!this.assignment?.author) {
            return '-';
        }

        return `${this.assignment?.author?.firstname} ${this.assignment?.author?.lastname}`;
    }

    get authorAssignedTime() {
        return this.assignment?.assigned_time
            ? formatDateTime(this.assignment.assigned_time)
            : '';
    }

    get companyName() {
        return this.assignment.author_request.company?.name || '';
    }

    get category() {
        return this.assignment.author_request.category?.name || '';
    }

    get commentsLink() {
        return `https://ampifire.com/app/announcements/review/${this.assignment.announcement_id}`;
    }

    get copyTooltip() {
        return this.copied ? 'Link is copied' : 'Click to copy link';
    }

    get announcementCreatedTime() {
        return this.assignment?.announcement
            ? formatDateTime(this.assignment.announcement.created)
            : '';
    }

    get canSeeDates() {
        return this.isAuthor || this.isEditor;
    }

    close() {
        this.$emit('close');

        this.reset();
    }

    update() {
        this.$emit('update', true);
    }

    onLinkCopied() {
        this.copied = true;

        setTimeout(this.reset, 3000);
    }

    reset() {
        this.copied = false;
    }

    redirect(location: string) {
        window.location.href = location;
    }
}
</script>
