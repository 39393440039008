<template>
    <modal :id="id" title="Claim Request">
        <template #default>
            <p>This will assign the request to your account.</p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="assign"
            >
                Claim
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

@Component({
    components: {
        Modal
    }
})
export default class AssignmentAssign extends Vue {
    id = 'author-request-assign';

    assign() {
        this.$emit('confirm', {
            action: 'assign'
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
