<template>
    <v-container v-if="loading" class="px-6 pt-6">
        <v-row>
            <v-col cols="11" class="pa-0">
                <v-skeleton-loader loading type="article" class="mb-4" />
            </v-col>
            <v-col cols="1" class="pa-0 pt-4">
                <v-skeleton-loader loading type="avatar" />
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <v-row
            v-if="!canComment"
            class="pa-2 main-background error--text text-center"
        >
            <v-col cols="12">
                <v-icon left color="error">comment</v-icon>
                <b>Chat is disabled</b>
            </v-col>
            <v-col v-if="isInProgress" cols="12" class="caption">
                This request is currently being worked on
            </v-col>
        </v-row>
        <v-row v-if="allowComments" class="pl-2 pb-3">
            <v-col>
                <a-form :submit="send">
                    <a-text-input
                        v-model="message"
                        label="Message"
                        textarea
                        rows="3"
                        rules="max:4000"
                        clearable
                        persistent-hint
                        auto-grow
                        :disabled="isSending"
                        :hint="warningText"
                    >
                        <template #append-outer>
                            <v-btn
                                type="submit"
                                :loading="isSending"
                                :disabled="isEmpty"
                                fab
                                text
                                title="Send"
                            >
                                <v-icon>paper-plane</v-icon>
                            </v-btn>
                        </template>
                    </a-text-input>
                </a-form>
            </v-col>
        </v-row>

        <modal :id="confirmationDialogId" title="Send to Editorial">
            <template #default>
                <p>
                    <v-icon color="error" left>circle-question</v-icon>
                    Would you also like to send this request back to authoring
                    team?
                </p>
            </template>
            <template #actions>
                <v-btn
                    text
                    :block="$vuetify.breakpoint.smAndDown"
                    @click="closeConfirmDialog"
                >
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                    color="primary"
                    :block="$vuetify.breakpoint.smAndDown"
                    @click="sendToEditorial"
                >
                    Send
                </v-btn>
            </template>
        </modal>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { InjectReactive } from '@/utils/decorators';

import { Modal } from '@/components/Modal';
import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { Assignment } from '@/types/Assignment';

const AssignmentCommentsInputProps = Vue.extend({
    name: 'AssignmentCommentsInput',
    props: {
        allowComments: {
            type: Boolean,
            default() {
                return false;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component({
    components: {
        AForm,
        ATextInput,
        Modal
    },
    computed: {
        ...mapGetters('user', ['isAuthor'])
    }
})
export default class AssignmentCommentsInput extends AssignmentCommentsInputProps {
    isAuthor!: boolean;

    message: string | null = null;

    confirmationDialogId = 'author-request-comment-resolved';

    isSending = false;

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get canComment() {
        return !(!this.isAuthor && !this.allowComments);
    }

    get isEmpty() {
        return !(this.message ? this.message.trim() : '');
    }

    get isInProgress() {
        return (
            !this.assignment.author_request.rejected_time &&
            [1, 2, 4].includes(this.assignment.status)
        );
    }

    get isResolved() {
        return this.assignment.status === 3;
    }

    get warningText() {
        if (this.isAuthor) {
            return 'Sending a message will notify the customer by email';
        }

        return 'Sending a message will notify the authoring team';
    }

    setSending(isSending = true) {
        this.isSending = isSending;
    }

    async send() {
        await this.sendMessage();

        if (this.isResolved) {
            this.$store.dispatch('modal/open', this.confirmationDialogId);
        }
    }

    sendMessage() {
        this.setSending();

        return this.sendData()
            .then(data => {
                this.message = '';
                if (data) {
                    if (data.redirect) {
                        this.$emit('redirect', data.redirect);
                    }

                    this.$emit('send');
                }
            })
            .finally(this.setSending.bind(this, false));
    }

    async sendData() {
        return this.$http
            .post(`/author_requests_pool/add_comment/${this.assignment.id}`, {
                message: this.message
            })
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to send message. Please try again later or contact support.'
                    );
                }

                return {};
            });
    }

    sendToEditorial() {
        this.closeConfirmDialog();

        this.$http
            .post(
                `/author_requests_pool/send_to_editorial/${this.assignment.id}`
            )
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    closeConfirmDialog() {
        this.$store.dispatch('modal/close', this.confirmationDialogId);
    }
}
</script>
