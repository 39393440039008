<template>
    <span>
        <v-speed-dial
            v-model="active"
            top
            right
            absolute
            direction="bottom"
            transition="slide-y-transition"
            class="author-controls ma-2"
        >
            <template #activator>
                <v-btn
                    icon
                    large
                    class="mb-n2 primary"
                    elevation="2"
                    color="white"
                >
                    <v-icon>
                        {{ active ? 'chevron-up' : 'ellipsis-v' }}
                    </v-icon>
                </v-btn>
            </template>

            <assignment-action
                v-for="(action, key) in availableActions"
                :key="key"
                :assignment="assignment"
                :action="action"
                v-on="$listeners"
            />

            <v-tooltip left>
                <template #activator="{ on, attrs }">
                    <v-btn
                        fab
                        dark
                        small
                        color="white"
                        v-bind="attrs"
                        @click="close"
                        v-on="on"
                    >
                        <v-icon small color="secondary">times</v-icon>
                    </v-btn>
                </template>
                <span>Close</span>
            </v-tooltip>
        </v-speed-dial>
        <assignment-action-dialogs v-on="$listeners" />
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { InjectReactive } from '@/utils/decorators';

import AssignmentActionsFactory from './AssignmentActionsFactory';
import AssignmentAction from './AssignmentAction.vue';

import { AssignmentActionDialogs } from './AssignmentActionDialogs';

import type { Assignment } from '@/types/Assignment';

const AssignmentActionsProps = Vue.extend({
    name: 'AssignmentActions',
    props: {
        canReview: {
            type: Boolean,
            default() {
                return false;
            }
        },
        canChangeFrequency: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AssignmentAction,
        AssignmentActionDialogs
    },
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AssignmentActions extends AssignmentActionsProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    active = false;

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get actions() {
        if (!this.assignment.id) {
            return [];
        }

        const AuthoringActions = new AssignmentActionsFactory(
            this.assignment,
            this.isEditor,
            this.isAuthor
        );

        return AuthoringActions.getActions();
    }

    get availableActions() {
        return this.actions.filter(action => {
            if (action.action === 'review' && !this.canReview) {
                return false;
            }

            if (action.action === 'write') {
                return false;
            }

            if (action.action === 'settings' && !this.canChangeFrequency) {
                return false;
            }

            return true;
        });
    }

    close() {
        this.$emit('close');
    }
}
</script>

<style lang="scss">
.author-controls {
    z-index: 6;
}
</style>
