<template>
    <modal :id="id" title="Send to Customer">
        <template #default>
            <p>
                This will submit the amp to the customer for review and notify
                them via email.
            </p>
            <v-checkbox
                v-model="notifySupport"
                label="Would you like to notify the support team about this request?"
            />
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="send"
            >
                Send
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

@Component({
    components: {
        Modal
    }
})
export default class AssignmentSendToCustomer extends Vue {
    id = 'author-request-send-to-customer';

    notifySupport = false;

    send() {
        this.$emit('confirm', {
            action: 'approve',
            data: {
                notifySupport: this.notifySupport
            }
        });

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
