<template>
    <v-card
        v-if="canRender"
        flat
        class="d-flex d-flex-column mb-6"
        :class="[comment.belongs_to_current_user ? 'flex-row-reverse' : '']"
        max-width="568"
    >
        <div :class="alignComment">
            <v-avatar :color="avatarColor" size="24" class="ma-2">
                <span class="white--text caption text-uppercase">
                    {{ initials }}
                </span>
            </v-avatar>
        </div>
        <div :class="alignComment" style="max-width: 90%">
            <div class="main-background rounded pa-2 mb-0">
                <p class="body-2 mb-0">
                    <span
                        v-if="comment.user && comment.message_type"
                        class="font-weight-bold text-caption mb-2"
                        :class="textColor"
                    >
                        {{ fullName }}
                        <i
                            v-if="comment.message_type === 6"
                            class="pl-2 text--secondary font-weight-light"
                        >
                            (private comment)
                        </i>
                    </span>
                    <span
                        v-else
                        class="font-weight-bold text-caption mb-2"
                        :class="textColor"
                    >
                        System Message
                    </span>
                    <br />
                    <!-- eslint-disable vue/no-v-html -->
                    <span
                        v-if="comment.message"
                        :class="textColor"
                        v-html="format(comment.message)"
                    ></span>
                    <!-- eslint-enable -->
                    <span v-else-if="comment.status_string" :class="textColor">
                        {{ comment.status_string }}
                    </span>
                </p>
            </div>
            <div class="caption pb-0 mb-0 justify-content-between">
                <a
                    v-if="canRenderLinkToAmp"
                    :href="linkToAmp"
                    target="_blank"
                    class="float-left pr-4"
                >
                    Related to {{ comment.announcement_id }}
                </a>
                <p
                    class="pb-0 mb-0 font-italic float-right"
                    :title="formatDate(comment.timestamp)"
                >
                    {{ fromNow(comment.timestamp) }}
                </p>
            </div>
        </div>
        <slot></slot>
    </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import { InjectReactive } from '@/utils/decorators';
import { formatDate, nl2br, urlify } from '@/utils/helpers';

import type { Assignment } from '@/types/Assignment';
import type { Comment } from '@/types/Comment';

const AssignmentCommentCardProps = Vue.extend({
    name: 'AssignmentCommentCard',
    props: {
        comment: {
            type: Object as PropType<Comment>,
            default() {
                return {
                    user: {
                        firstname: '',
                        lastname: ''
                    },
                    message: '',
                    message_type: null,
                    status: -1,
                    status_string: '',
                    timestamp: 0,
                    belongs_to_current_user: false,
                    announcement_id: 0
                };
            }
        }
    }
});

@Component({
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor'])
    }
})
export default class AssignmentCommentCard extends AssignmentCommentCardProps {
    isAuthor!: boolean;
    isEditor!: boolean;

    @InjectReactive({
        from: 'assignment',
        default() {
            return {
                id: 0
            };
        }
    })
    assignment!: Assignment;

    get canRender() {
        return (
            this.comment && (this.comment.message || this.comment.status_string)
        );
    }

    get canRenderLinkToAmp() {
        return (
            this.linkToAmp &&
            this.isRecurring &&
            (this.isAuthor || this.isEditor)
        );
    }

    get isRecurring() {
        return this.assignment.author_request.frequency !== null;
    }

    get initials() {
        if (!this.comment.user || !this.comment.message_type) {
            return 'S';
        }
        return (
            this.comment.user.firstname.charAt(0) +
            this.comment.user.lastname.charAt(0)
        );
    }

    get avatarColor() {
        if (this.comment.message_type === 6) {
            return 'warning';
        }
        if (this.comment.status === 3) {
            return 'error';
        }
        if (this.comment.belongs_to_current_user) {
            return 'primary';
        }
        if (!this.comment.user || !this.comment.message_type) {
            return 'turquoise';
        }
        return 'secondary';
    }

    get textColor() {
        return `${this.avatarColor}--text`;
    }

    get linkToAmp() {
        if (!this.comment.announcement_id) {
            return '';
        }

        return `/app/announcements/review/${this.comment.announcement_id}`;
    }

    get fullName() {
        if (!this.comment.user.firstname || !this.comment.user.lastname) {
            return '';
        }
        return `${
            this.comment.user.firstname
        } ${this.comment.user.lastname.charAt(0)}.`;
    }

    get alignComment() {
        return this.comment.belongs_to_current_user
            ? 'align-end'
            : 'align-start';
    }

    format(text: string) {
        return nl2br(urlify(text));
    }

    formatDate = formatDate;

    fromNow(value: number) {
        return this.$date.unix(value).fromNow();
    }
}
</script>
